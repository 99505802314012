// Tutorial: https://www.youtube.com/watch?v=5If5QGVY03Q
import * as THREE from 'three';
import { Suspense, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useGLTF, Environment } from "@react-three/drei";
import { EffectComposer, DepthOfField } from "@react-three/postprocessing";

function Banana(props) {
  const ref = useRef();
  //  const { nodes, materials } = useGLTF('/banana-v1-transformed.glb');
  //  const { nodes, materials } = useGLTF('/pin-v1.glb');
  const { nodes, materials } = useGLTF(`/pin_${props.textureNum + 1}-v1.glb`);
//  const { nodes, materials } = useGLTF(`/pin_1-v1.glb`);
  const { viewport, camera } = useThree();
  const { width, height } = viewport.getCurrentViewport(camera, [0,0,props.z]);
  const [data] = useState({
    // NOTE:
    // cool util. gives values from -3 to 3. using native
    // Math.random() is only positive numbers
    x: THREE.MathUtils.randFloatSpread(2),
    y: THREE.MathUtils.randFloatSpread(height),
    rX:  Math.random() * Math.PI,
    rY:  Math.random() * Math.PI,
    rZ:  Math.random() * Math.PI,
  });

  useFrame((state) => { // in here, many useful properties, RTFM
    ref.current.position.set(data.x * width, data.y += 0.01, props.z);
    //    ref.current.rotation.set(Math.PI, 0, 0);
    //ref.current.rotation.set(Math.PI, (data.rY += 0.01), (data.rZ += 0.01));
    ref.current.rotation.set((data.rX += 0.001), (data.rY += 0.001), (data.rZ += 0.001));
    if (data.y > height) {
      data.y = -height;
    }
  });
  // banana: nodes.Object_4.geometry
  return (
    <group ref={ref} {...props} dispose={null}>
      <mesh
        geometry={nodes.pin.geometry}
        material={materials.skin}
      />
    </group>
  )
}

export default function Background({ count = 25, depth = 80 }) {
  return (
    <>
      <Canvas gl={{ alpha: false }} camera={{ near: 0.01, far: 40, fov: 30}}>
        <color attach="background" args={["beige"]} />
        <pointLight position={[10,10,10]} intensity={1} />
        <Suspense fallback={null}>
          { Array.from({ length: count }, (_, i) => (
            <Banana scale={0.5} key={i} textureNum={i} z={-(i / count) * depth} />)
            )}
          <Environment preset="sunset" />
          <EffectComposer>
            <DepthOfField target={[0,0,depth / 2]} focalLength={1} bokehScale={10} height={700} />
          </EffectComposer>
        </Suspense>
      </Canvas>
    </>
  )
}
