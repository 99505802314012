import React, { useRef, Suspense } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { useTexture, OrbitControls } from "@react-three/drei";

export default function Pin3D({ image }) {
  const objRef = useRef();
  const obj = useLoader(OBJLoader, 'pinbutton.obj')
  const aoMap = useLoader(TextureLoader, 'textures/PinButton_AO.png');
  const normalMap = useLoader(TextureLoader, 'textures/PinButton_Normal.png');

  // NOTE: this map is 800 x 800 wide, can make smaller if it helps performance?
  //  const colorMap = useLoader(TextureLoader, 'textures/PinButton_Frankenstein2.png');
  const colorMap = useLoader(TextureLoader, image);
  const roughnessMap = useLoader(TextureLoader, 'textures/PinButton_Roughness.png');

  function Scene() {
    obj.traverse(child => {
      if (child.isMesh) {
        child.material.aoMap = aoMap;
        child.material.normalMap = normalMap;
        child.material.map = colorMap;
        child.material.roughnessMap = roughnessMap;
      }
    });
    obj.rotation.z = Math.PI / 2; // Rotate around the x-axis
    obj.rotation.y = Math.PI / 2; // Rotate around the x-axis
    useFrame(() => {
      // Update rotation on each frame
      if (objRef.current) {
        objRef.current.rotation.y -= 0.01; // Adjust the rocking speed here
      }
    });

    return (
      <>
        <ambientLight intensity={0.5} />
        <directionalLight />
        <primitive ref={objRef} object={obj} />
      </>
    )
  }

  return (
    <Canvas id="pin3d">
      <Suspense fallback={null}>
        <Scene />
      </Suspense>
    </Canvas>
  )
}
