import React, { useState } from 'react';
import Pin3D from './Pin3D';

export default function PreviewButton({ image }) {
  return (
    <div className="text-center mb-4">
      <Pin3D image={image} />
      <span><em>approximate</em> rendering*</span>
    </div>
  )
}
