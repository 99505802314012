/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 pinbutton.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Pinbutton(props) {
  const { nodes, materials } = useGLTF('/pinbutton.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.pinbutton.geometry} material={materials.initialShadingGroup} rotation={[Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('/pinbutton.glb')
