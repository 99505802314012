import React, { useEffect, useRef, useState, Suspense } from 'react';
import html2canvas from 'html2canvas';
import AvatarEditor from 'react-avatar-editor'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { useTexture, OrbitControls } from "@react-three/drei";
import PinButton from './Pinbutton';
import Pin3D from './Pin3D';
import IconClose from './IconClose';
import IconImageSelect from './IconImageSelect';

const PRINT_WIDTHS_INCHES = {
  standard: 1.7 // 1.25" buttons
};

export default function Editor({ onRenderPreview, onCloseEditor }) {
  const overlayRef = useRef(null);
  const localCropRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [scale, setScale] = useState(1.2);
  const [rotation, setRotation] = useState(0);
  const [dpi, setDpi] = useState(null);
  const editorRef = useRef(null);
  const fileInputRef = useRef(null);

  function handleImageUpload(event) {
    const { width, height } = event.target;
    const file = event.target.files[0];

    const image = new Image();
    image.onload = function() {
      const width = image.width;
      const height = image.height;
      console.log(`Image width: ${width}px, height: ${height}px`);
      const _dpi = Math.sqrt(width * height) / Math.sqrt(PRINT_WIDTHS_INCHES.standard**2);
      console.log(`DPI: ${_dpi}`);
      setDpi(_dpi);
    };

    const reader = new FileReader();
    reader.onload = function(event) {
      image.src = event.target.result;
    };

    reader.readAsDataURL(file);
    setSelectedImage(file);
  }


  function changeScale(e) {
    setScale(e.target.value);
  }

  function onRotate(e) {
    setRotation(e.target.value);
  }
  function onCrop(e) {
    const canvas = editorRef.current.getImageScaledToCanvas();//.toDataURL();
    const canvas3D = document.createElement('canvas');
    const canvasCrop = document.createElement('canvas');

    // Set the dimensions of the new canvas
    canvas3D.width = canvas.width;
    canvas3D.height = canvas.height;

    canvasCrop.width = canvas.width;
    canvasCrop.height = canvas.height;

    // Get the 2D context of the new canvas
    const ctx3D = canvas3D.getContext('2d');
    const ctxCrop = canvasCrop.getContext('2d');

    // Calculate the radius of the circle
    const inRadius = 142;
    const outRadius = 200;

    // Create a circular clipping path
    ctx3D.arc(outRadius, outRadius, inRadius, 0, 2 * Math.PI);
    ctx3D.clip();
    ctxCrop.arc(outRadius, outRadius, outRadius, 0, 2 * Math.PI);
    ctxCrop.clip();

    // Draw the original canvas onto the new canvas
    ctx3D.drawImage(canvas, 0, 0);
    ctxCrop.drawImage(canvas, 0, 0);

    // Create a new img element
    let croppedImg3D = canvas3D.toDataURL();
    let cropForPrint = canvasCrop.toDataURL();

    // Add the 3D crop to the dom for composing
    localCropRef.current.src = croppedImg3D;

    html2canvas(overlayRef.current).then((composedCanvas) => {
      onRenderPreview({ composed: composedCanvas.toDataURL(), cropped: cropForPrint });
    });
  }

  function selectFile() {
    fileInputRef.current.click();
  }

  useEffect(() => {
    selectFile();
  }, []);

  return (
    <>
      <input className="filePicker" ref={fileInputRef} type="file" accept="image/png, image/jpeg" onChange={handleImageUpload} />
      {!selectedImage && (
        <div className="magicShadow magicRadius cursor-pointer bg-white">
          <div className="flex flex-row justify-start m-4 text-gray-300" onClick={onCloseEditor}>
            <IconClose />
          </div>
          <div className="flex flex-col justify-center items-center px-24 pb-24" onClick={selectFile}>
            <IconImageSelect />
            <span className="mt-4 text-lg font-semibold ">Click to edit button image</span>
          </div>
        </div>
      )}
        {!!selectedImage && (
          <>
            <div id="editor" className="magicShadow">
              <div id="canvas-container">
                <AvatarEditor
                  id="avatar-editor"
                  ref={editorRef}
                  image={selectedImage}
                  width={400}
                  height={400}
                  border={50}
                  borderRadius={200}
                  backgroundColor="white"
                  color={[255, 255, 255, 0.6]}
                  scale={scale}
                  rotate={rotation} />
                <div id="avatar-inner-circle"></div>
              </div>
              <div className="w-72 text-sm text-left mt-4">
                <strong>*IMPORTANT*</strong>
                <ul className="m-4 mt-0 list-disc">
                  <li>Use the best quality image you have. The better the quality, the better the button.</li>
                  <li>Stay within the dotted line. The region just outside the dotted line is the crimped edge.</li>
                </ul>
              </div>
              <div id="controls" className="mt-4">
                <div>
                  <span>Zoom: </span>
                  <input
                    type="range"
                    min={0}
                    max={2}
                    step={0.01}
                    value={scale}
                    onChange={changeScale}
                  />
                </div>
                <div>
                  <span>Rotate: </span>
                  <input
                    type="range"
                    min={0}
                    max={360}
                    step={1}
                    value={rotation}
                    onChange={onRotate}
                  />
                </div>
                <div id="editorButtons" className="mt-4">
                  <button
                    type="button"
                    className="rounded-md bg-neutral-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-neutral-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-300"
                    onClick={onCloseEditor}>cancel</button>
                  <button
                    type="button"
                    className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={onCrop}>Crop!</button>
                </div>
              </div>
            </div>
            <div ref={overlayRef} className="image-container">
              <img src="textures/PinButton_Template2.png" alt="Circular Image" />
              <img ref={localCropRef}  alt="Overlay Image" className="overlay-image" />
            </div>
          </>
        )}
    </>
  );
}
