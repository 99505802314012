import React, { useState } from 'react';
import NewButton from './NewButton';
import Editor from './Editor';
import PreviewButton from './PreviewButton';
import SelectCount from './SelectCount';
import ShoppingCart from './ShoppingCart';

const products = [
  {
    buttonId: 1,
    count: 1,
    priceCents: 300,
    description: '1.25" pinback',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-01-product-03.jpg',
  },
  {
    buttonId: 2,
    count: 2,
    priceCents: 300,
    description: '1.25" magnet',
    size: '1.25"',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-01-product-01.jpg',
  }
];

export default function ButtonCreator() {
  const [cart, setCart] = useState([]);
  const [editorOpen, setEditorOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [composedImage, setComposedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  function onRenderPreview({ composed, cropped }) {
    setCroppedImage(cropped);
    setComposedImage(composed);
  }
  function onCloseEditor() {
    setEditorOpen(false);
  }
  function clearSelectedButton() {
    setCroppedImage(null);
    setComposedImage(null);
  }
  function addMore() {
    setEditorOpen(true);
  }
  function openEditor() {
    setEditorOpen(true);
  }
  async function uploadButton({ count, type }) {
    try {
      setUploading(true);
      const settings = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          body: croppedImage
        })
      };
      const fetchResponse = await fetch(`https://api.magicbutton.club/createButton`, settings);
      const { buttonId } = await fetchResponse.json();
      const newCart = [...cart];
      newCart.push({
        buttonId,
        count,
        type,
        priceCents: 300,
        description: '1.25" pinback',
        imageSrc: croppedImage,
      });
      setCart(newCart);
    } catch (e) {
      console.log(e);
      setError(e.message);
    } finally {
      setUploading(false);
    }
  }

  async function onContinue(count) {
    await uploadButton({ count, type: 'standard_pin_1p25' });
    setEditorOpen(false);
    clearSelectedButton();
  }
  return (
    <div className="flex flex-col justify-between items-center">
      {editorOpen && !composedImage && (
        <Editor
          onRenderPreview={onRenderPreview}
          onCloseEditor={onCloseEditor}
        />
      )}
      {!!uploading && (
        <div>Adding to cart...</div>
      )}
      {editorOpen && composedImage && !uploading &&(
        <>
          <PreviewButton image={composedImage} />
          <SelectCount
            onGoBack={clearSelectedButton}
            onContinue={onContinue}
          />
        </>
      )}
      {!editorOpen && cart.length == 0 && (
        <button
          type="button"
          className="mt-24 pulsing magicShadow mb-8 rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          onClick={openEditor}
        >Create a Button</button>
      )}
      {!editorOpen && cart.length > 0 && (
        <ShoppingCart
          cart={cart}
          addMore={addMore}
          updateCart={setCart}
        />
      )}
    </div>
  )
}
