import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What is the size of the button?",
    answer:
      "For now, we only sell pin-back buttons of 1.25 inches in diameter. If you really want a different size, please email us. It's helpful for us to hear that!"
  },
  {
    question: "What do they look like?",
    answer:
      "You've seen them millions of times. Worn on jackets for bands, politicians and anyone who likes fun.",
    img: "/example_button.png"
  },
  {
    question: "How can we reach you?",
    answer:
      "Email us at hello {at} magicbutton.club"
  },
  {
    question: "How much does a button cost?",
    answer:
      "Our pin-back button costs $3. This includes shipping costs."
  },
  {
    question: "Why not Etsy or other platforms?",
    answer:
      "We also love making websites! This gives us more control of your customer experience and paying less vendors means we can keep the service around longer!"
  },
  {
    question: "What makes a good button?",
    answer:
      "Our favorite question! In our opinion, anything that makes you feel good the instant you see it. Family photos, art work, internet memes. The only limit is your imagination!"
  },
  {
    question: "What is your return policy?",
    answer:
      "We are so confident you will love your button, we haven't had to think about this! That being said, if you aren't 100% happy with your experience, send it back and you'll get a full refund.",
  },
  {
    question: "How do you accept payment?",
    answer:
      "We use Stripe as our payment processor. Charges will appear as MAGICBUTTON.CLUB. Our company name is MeMeTiMe LLC.",
  },
  {
    question: "What makes these buttons magic?",
    answer:
      "Love"
  },
]

function FAQ() {
  return (
    <div className="mx-auto max-w-7xl mt-4">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">FAQ...</h2>
        <dl className="mt-4 space-y-6 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                      <span className="text-base font-semibold leading-7">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-start">
                        {open ? (
                          <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    {!!faq.img && <img src={faq.img} /> }
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  )
}


export default function Description() {
  return (
    <div className="px-6 py-24 sm:py-32 lg:px-8 mt-24">
      <div className="mx-auto max-w-2xl text-left">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">How it works...</h2>
        <ol className="ml-6 mt-6 text-lg leading-8 text-gray-600 list-decimal">
          <li>Upload a <em>high quality</em> image you want on a pin-back button (aka badge, aka pinback button).</li>
          <li>Pay us $3 per magic button.</li>
          <li>Tell us where to mail them.</li>
          <li>Wait a couple days and you'll have your new buttons (and will have joined the Magic Button Club)!</li>
        </ol>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Unless we are away from our home on vacation—which doesn't happen as often as we'd like 😜— a typical order is processed within 24 Hours. That means you'll have a magic button on your shirt / jacket / sweater as fast as the USPS mail can get it from Los Angeles, CA to your residence. For the time being, we only ship within the USA. 🇺🇸
        </p>
      </div>
      <div className="mx-auto max-w-2xl text-left mt-4">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">About us...</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          We are a creative family in Los Angeles, CA who love making buttons. It's not our full-time job, but we love it and it helps us build for our future!
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Drop us an email with any feedback you have or if you just want to say hi! <a className="text-blue-400" href="mailto:hello@magicbutton.club">hello@magicbutton.club</a>
        </p>
      </div>
      <FAQ />
      <div className="relative mt-16">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
      <img src="/stripe_badge.png" />
    </div>
  )
}
