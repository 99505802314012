import React, { useEffect, useState } from 'react';
import ButtonCreator from './ButtonCreator';
import Description from './Description';
import ShoppingCart from './ShoppingCart';
import PurchaseSuccess from './PurchaseSuccess';
import Background from './Background';

import './Main.css';
function App() {
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  function dismissPurchaseSuccess() {
    setPurchaseSuccess(false);
  }
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath == "/purchaseSuccess") {
      setPurchaseSuccess(true);
    }
  }, []);
  return (
    <>
    <Background />
    <div className="App">
      { !!purchaseSuccess && (
          <div className="mt-4">
            <PurchaseSuccess onDismiss={dismissPurchaseSuccess} />
          </div>
        )}
      <header>
        <div id="logo">
          <a href="/"><img src="/magicbutton_logomark.png" /></a>
        </div>
      </header>
      <main>
        <ButtonCreator />
        <Description />
      </main>
    </div>
    </>
  );
}

export default App;
