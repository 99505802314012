import React, { useState } from 'react';

export default function SelectCount({ onGoBack, onContinue }) {
  const [count, setCount] = useState(1);
  function increment() {
    setCount(count + 1);
  }
  function decrement() {
    setCount(Math.max(count - 1, 1));
  }
  function addToCart() {
    onContinue(count);
  }
  return (
    <div id="selectCount">
      <h1 className="text-2xl text-center">How many do you want?</h1>
      <div className="my-4">
        <button
          type="button"
          className="rounded-md bg-neutral-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-neutral-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-300"
          onClick={decrement}
        >-</button>
        <h1 className="text-4xl">{count}</h1>
        <button
          type="button"
          className="rounded-md bg-neutral-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-neutral-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-300"
          onClick={increment}
        >+</button>
      </div>
      <div>
        <button
          onClick={onGoBack}
          className="rounded-md bg-neutral-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-neutral-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-300"
        >Go back</button>
        <button
          onClick={addToCart}
          className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >Continue</button>
      </div>
    </div>
  )
}
