export default function IconImageSelect() {
  return (
    <svg width="100px" height="100px" version="1.1" viewBox="0 0 1200 1200">
      <g>
        <path d="m976.8 628.88v-433.26c0-72.211-57.77-129.98-129.98-129.98h-716.85c-72.207 0-129.98 57.77-129.98 129.98v716.85c0 72.211 57.77 129.98 129.98 129.98h619.7c45.953 56.457 116.85 91.902 195.62 91.902 140.48 0 253.39-114.22 253.39-253.39 1.3125-129.98-97.156-237.64-221.88-252.08zm-925.6-433.26c0-43.324 35.449-78.773 78.773-78.773h716.85c43.324 0 78.773 35.449 78.773 78.773v403.06l-128.66-128.66c-38.074-38.074-101.09-38.074-139.17 0l-262.58 263.89-81.402-81.402c-22.32-22.32-52.516-35.449-84.027-35.449-31.508 0-61.707 13.129-85.336 35.449l-94.531 94.531zm78.773 795.62c-43.324 0-78.773-35.449-78.773-78.773v-93.215l129.98-129.98c13.129-13.129 30.195-19.695 47.266-19.695 17.066 0 35.449 6.5664 47.266 19.695l301.97 301.97zm300.66-220.57 263.89-263.89c9.1914-9.1914 21.008-13.129 32.824-13.129 11.816 0 24.945 5.25 32.824 13.129l127.35 127.35c-112.91 26.258-196.94 127.35-196.94 246.83 0 39.387 9.1914 77.461 24.945 110.29h-65.645zm515.98 312.47c-111.6 0-203.5-90.59-203.5-203.5s90.59-203.5 203.5-203.5 203.5 90.59 203.5 203.5c0 112.91-91.902 203.5-203.5 203.5z"/>
        <path d="m1054.3 854.7h-82.711v-82.715c0-14.441-11.816-24.945-24.945-24.945s-24.945 11.816-24.945 24.945v82.715h-82.715c-14.441 0-24.945 11.816-24.945 24.945 0 13.129 11.816 24.945 24.945 24.945h82.715v82.715c0 14.441 11.816 24.945 24.945 24.945s24.945-11.816 24.945-24.945v-81.402h82.715c14.441 0 24.945-11.816 24.945-24.945-0.003906-13.129-10.508-26.258-24.949-26.258z"/>
        <path d="m408.32 267.83c-57.77 0-105.03 47.266-105.03 105.03 0 57.77 47.266 105.03 105.03 105.03 57.77 0 105.03-47.266 105.03-105.03s-47.266-105.03-105.03-105.03zm0 158.86c-30.195 0-53.828-24.945-53.828-53.828 0-28.883 24.945-53.828 53.828-53.828 28.883 0 53.828 24.945 53.828 53.828 0 28.883-23.633 53.828-53.828 53.828z"/>
      </g>
    </svg>
  )
}
