import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid'
import Spinner from './Spinner';

function formatPrice(priceCents) {
  return `$${(priceCents / 100).toFixed(2)}`;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ShoppingCart({ cart, addMore, updateCart }) {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  function removeItem(idx) {
    const newCart = [...cart];
    newCart.splice(idx,1);
    updateCart(newCart);
  }
  function changeCount(idx) {
    return (e) => {
      const newCart = [...cart];
      const item = cart[idx];
      item.count = parseInt(Math.max(e.target.value, 1), 10);
      updateCart(newCart);
    }
  }
  async function checkout() {
    try {
      setUploading(true);
      const buttons = cart.map(({ count, type, buttonId }) => {
        return { count, type, button_id: buttonId }
      });
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ buttons })
      };
      const initializeResponse = await fetch(`https://api.magicbutton.club/initializeOrder`, opts);
      const { redirect_to } = await initializeResponse.json();
      window.location = redirect_to;
    } catch(e) {
      console.log(e);
      setError(e.message);
    } finally {
      setUploading(false);
    }
  }
  const totalPrice = cart.reduce((acc, curr) => acc + (curr.priceCents * curr.count), 0);
  const checkoutText = uploading ? <Spinner /> : 'Checkout';
  return (
    <div className="w-full bg-white magicShadow rounded-xl">
      <div className="px-4 pb-16">
        {!!error && <span>{error}</span> }
        <form>
          <div>
            <h2 className="sr-only">Items in your shopping cart</h2>

            <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
              {cart.map((button, buttonIdx) => (
                <li key={button.buttonId} className="flex py-6">
                  <div className="flex-shrink-0">
                    <img
                      src={button.imageSrc}
                      alt={button.description}
                      className="h-24 w-24 rounded-lg object-cover object-center"
                    />
                  </div>

                  <div className="relative ml-4 flex flex-1 flex-col justify-betwee">
                    <div>
                      <div className="flex justify-between">
                        <div className="pr-6">
                          <h3 className="text-sm">
                            {button.description}
                          </h3>
                        </div>

                        <p className="text-right text-sm font-medium text-gray-900">{formatPrice(button.priceCents)}/each</p>
                      </div>

                      <div className="mt-4 flex items-center">
                        <label htmlFor={`quantity-${buttonIdx}`} className="sr-only">
                          Quantity, {button.name}
                        </label>

                        <input
                          type="number"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          id="exampleFormControlInputNumber"
                          onChange={(e) => changeCount(buttonIdx)(e) }
                          value={button.count} />
                        <button
                          type="button"
                          className="ml-4 text-sm font-medium text-blue-600 hover:text-blue-500"
                          onClick={() => removeItem(buttonIdx) }
                        >
                          <span>Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/* Order summary */}
          <div className="w-full mt-10">
            <div className="rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:p-8">
              <h2 className="sr-only">Order summary</h2>

              <div className="flow-root">
                <dl className="-my-4 divide-y divide-gray-200 text-sm">
                  <div className="flex items-center justify-between py-4">
                    <dt className="text-gray-600">Subtotal</dt>
                    <dd className="font-medium text-gray-900">{formatPrice(totalPrice)}</dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="mt-10 flex flex-row justify-around items-center">
              <button
                type="button"
                className="rounded-md text-blue-600 border border-transparent bg-blue-50 px-4 py-3 text-base shadow-sm hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-100 focus:ring-offset-2 focus:ring-offset-gray-50"
                onClick={addMore}
              >
                Add More
              </button>
              <button
                type="button"
                className={classNames(
                  uploading ? "bg-blue-300 hover:bg-blue-400 focus:ring-blue-200 focus:ring-offset-gray-50" : "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-gray-50",
                  "rounded-md border border-transparent px-4 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                )}
                onClick={checkout}
                disabled={!!uploading}
              >
                {checkoutText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
